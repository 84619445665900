import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import {
	json,
	type ActionFunctionArgs,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { Form, Link, useActionData, useSearchParams } from '@remix-run/react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { z } from 'zod'
import { Box } from '#app/components/Box.js'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { CheckboxField, ErrorList, Field } from '#app/components/forms.tsx'
import { HorizontalSeparator } from '#app/components/ui/Separator.js'
import { StatusButton } from '#app/components/ui/status-button.tsx'
import { login, requireAnonymous } from '#app/utils/auth.server.ts'
import {
	ProviderConnectionForm,
	providerNames,
} from '#app/utils/connections.tsx'
import { APP_NAME } from '#app/utils/constants.js'
import { checkHoneypot } from '#app/utils/honeypot.server.ts'
import { useIsPending } from '#app/utils/misc.tsx'
import { EmailSchema, PasswordSchema } from '#app/utils/user-validation.ts'
import { handleNewSession } from './login.server.ts'

const LoginFormSchema = z.object({
	email: EmailSchema,
	password: PasswordSchema,
	redirectTo: z.string().optional(),
	remember: z.boolean().optional(),
})

export async function loader({ request }: LoaderFunctionArgs) {
	await requireAnonymous(request)
	return json({})
}

export async function action({ request }: ActionFunctionArgs) {
	await requireAnonymous(request)
	const formData = await request.formData()
	checkHoneypot(formData)
	const submission = await parseWithZod(formData, {
		schema: intent =>
			LoginFormSchema.transform(async (data, ctx) => {
				if (intent !== null) return { ...data, session: null }

				const session = await login(data)
				if (!session) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Invalid email or password',
					})
					return z.NEVER
				}

				return { ...data, session }
			}),
		async: true,
	})

	if (submission.status !== 'success' || !submission.value.session) {
		return json(
			{ result: submission.reply({ hideFields: ['password'] }) },
			{ status: submission.status === 'error' ? 400 : 200 },
		)
	}

	const { session, remember, redirectTo } = submission.value

	return handleNewSession({
		request,
		session,
		remember: remember ?? false,
		redirectTo,
	})
}

export default function LoginPage() {
	const actionData = useActionData<typeof action>()
	const isPending = useIsPending()
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get('redirectTo')

	const [form, fields] = useForm({
		id: 'login-form',
		constraint: getZodConstraint(LoginFormSchema),
		defaultValue: { redirectTo },
		lastResult: actionData?.result,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: LoginFormSchema })
		},
		shouldRevalidate: 'onBlur',
	})

	return (
		<div className="flex min-h-full flex-col justify-center pb-32 pt-20">
			<div className="mx-auto w-full max-w-md">
				<WelcomeBack />
				<div className="mx-auto w-full max-w-md px-8">
					<Box>
						<Form method="POST" {...getFormProps(form)}>
							<HoneypotInputs />
							<Field
								labelProps={{ children: 'Email' }}
								inputProps={{
									...getInputProps(fields.email, { type: 'text' }),
									autoFocus: true,
									className: 'lowercase',
									autoComplete: 'email',
								}}
								errors={fields.email.errors}
							/>

							<Field
								labelProps={{ children: 'Password' }}
								inputProps={{
									...getInputProps(fields.password, {
										type: 'password',
									}),
									autoComplete: 'current-password',
								}}
								errors={fields.password.errors}
							/>

							<div className="flex justify-between">
								<CheckboxField
									labelProps={{
										htmlFor: fields.remember.id,
										children: 'Remember me',
									}}
									buttonProps={getInputProps(fields.remember, {
										type: 'checkbox',
									})}
									errors={fields.remember.errors}
								/>
								<div>
									<Link to="/forgot-password" className="text-gray-600">
										Forgot password?
									</Link>
								</div>
							</div>

							<input
								{...getInputProps(fields.redirectTo, { type: 'hidden' })}
							/>
							<ErrorList errors={form.errors} id={form.errorId} />

							<StatusButton
								className="mt-4 w-full"
								status={isPending ? 'pending' : form.status ?? 'idle'}
								type="submit"
								disabled={isPending}
							>
								Log in
							</StatusButton>
						</Form>
						<HorizontalSeparator margin="28px 0 24px 0" />
						<SSOProviders />
						<CreateAccountPrompt />
					</Box>
				</div>
			</div>
		</div>
	)
}

function WelcomeBack() {
	return (
		<div className="mb-8 flex flex-col gap-2 text-center">
			<h1 className="text-2xl">Welcome back!</h1>
			<p className="text-sm tracking-tight text-muted-foreground">
				Please enter your details.
			</p>
		</div>
	)
}

function SSOProviders() {
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get('redirectTo')
	return (
		<ul className="flex flex-col gap-4">
			{providerNames.map(providerName => (
				<li key={providerName}>
					<ProviderConnectionForm
						type="Login"
						providerName={providerName}
						redirectTo={redirectTo}
					/>
				</li>
			))}
		</ul>
	)
}

function CreateAccountPrompt() {
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get('redirectTo')
	return (
		<div className="flex items-center justify-center gap-2 pt-8">
			<span className="text-muted-foreground">New here?</span>
			<Link
				to={
					redirectTo ? `/signup?${encodeURIComponent(redirectTo)}` : '/signup'
				}
			>
				Create an account
			</Link>
		</div>
	)
}

export const meta: MetaFunction = () => {
	return [{ title: `Login to ${APP_NAME}` }]
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
